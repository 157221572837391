// scoreboardStore.ts
import { defineStore } from 'pinia';
import { createStore } from 'matrix-display-store';
import { LedMatrix } from 'led-matrix';
import { useCanoePolo, useNetball, useHockey } from '~/scoreboards';
import { useBreakpoints, useElementSize, useNow } from '@vueuse/core';
import { ref, computed, watch } from 'vue';

const breakpoints = useBreakpoints({
    mobile: 0,
    tablet: 768,
    laptop: 1024,
    desktop: 1280,
});

const mobile = breakpoints.between('mobile', 'tablet');
const tablet = breakpoints.between('tablet', 'laptop');

interface ScoreboardOptions {
    sportType: string;
    autostart: boolean;
}

const height = 64;
const width = 96;

export const useScoreboardStore = defineStore('scoreboard', () => {
    const store = ref<ReturnType<typeof createStore>>();
    const tempGameStore = useTempGameStore();
    const matrix = ref<LedMatrix | null>(null);

    const sportType = ref<string>();
    const setSportType = (type: string) => {
        sportType.value = type;
    };

    const totalGameTime = ref(600); // Total game time in seconds

    const gameStarted = ref(false);
    const paused = ref(true);
    const scoreA = ref(0);
    const scoreB = ref(0);

    const shotClockTime = ref(60);
    const shotClockPassed = ref(0);

    // Use useNow for reactivity
    const currentTime = useNow({ interval: 1000 });

    // Local game start time
    const gameStartTime = ref<number | null>(null);

    const pausedTimeLeft = ref<number | null>(null);

    const timeLeft = computed(() => {
        if (gameStarted.value) {
            if (paused.value) {
                // Game is paused
                return pausedTimeLeft.value !== null ? pausedTimeLeft.value : totalGameTime.value * 1000;
            } else if (gameStartTime.value !== null) {
                // Game is running
                const now = currentTime.value;
                const elapsedMilliseconds = now - gameStartTime.value;
                const remainingMilliseconds = totalGameTime.value * 1000 - elapsedMilliseconds;
                return remainingMilliseconds > 0 ? remainingMilliseconds : 0;
            } else {
                // Edge case: gameStartTime is null while game is not paused
                return totalGameTime.value * 1000;
            }
        } else {
            // Game has not started
            return totalGameTime.value * 1000;
        }
    });

    function updateTotalGameTime() {
        const gameClock = tempGameStore?.tempGame?.data?.game_clock;
        if (gameClock) {
            totalGameTime.value = gameClock.minutes * 60 + gameClock.seconds;
        }
    }

    // If hasGame changes, update the game
    watch(
        () => tempGameStore.hasGame,
        (hasGame) => {
            if (hasGame) {
                scoreA.value = tempGameStore?.tempGame?.data?.teams[0].score || 0;
                scoreB.value = tempGameStore?.tempGame?.data?.teams[1].score || 0;

                updateTotalGameTime();

                const gameClock = tempGameStore?.tempGame?.data?.game_clock;

                if (gameClock.is_running && gameClock.started_at) {
                    gameStarted.value = true;
                    paused.value = false;
                } else {
                    gameStarted.value = false;
                    paused.value = true;
                }
            }
        }
    );

    const formattedTimeLeft = computed(() => {
        const totalSeconds = Math.ceil(timeLeft.value / 1000);
        const minutes = Math.floor(totalSeconds / 60);
        const seconds = totalSeconds % 60;
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    });

    const formattedShotClock = computed(() => {
        let remainingTime = shotClockTime.value - shotClockPassed.value;
        return remainingTime > 0 ? remainingTime : 0;
    });

    async function createScoreboard(
        canvasElement: HTMLCanvasElement | undefined,
        container: HTMLDivElement | undefined,
        options: ScoreboardOptions
    ) {
        if (!canvasElement || !container) {
            return;
        }

        const { width: containerWidth, height: containerHeight } = useElementSize(container);

        const margin = computed(() => {
            return mobile.value || tablet.value ? 0.5 : 2;
        });

        const sizing = computed(() => {
            const aspectRatio = width / height;
            const containerAspectRatio = containerWidth.value / containerHeight.value;

            let pixelSize;
            if (containerAspectRatio > aspectRatio) {
                // Calculate pixel size based on height, including margins
                pixelSize = (containerHeight.value - (height + 1) * margin.value) / height;
            } else {
                // Calculate pixel size based on width, including margins
                pixelSize = (containerWidth.value - (width + 1) * margin.value) / width;
            }

            return {
                x: width,
                y: height,
                m: margin.value,
                p: pixelSize,
            };
        });

        store.value = createStore(width, height);
        matrix.value = new LedMatrix(canvasElement, {
            x: sizing.value.x,
            y: sizing.value.y,
            pixelWidth: sizing.value.p,
            pixelHeight: sizing.value.p,
            margin: sizing.value.m,
            glow: true,
        });

        // Watch for changes in the container size
        watch([containerWidth], () => {
            matrix.value?.setNewOptions({
                x: sizing.value.x,
                y: sizing.value.y,
                pixelWidth: sizing.value.p,
                pixelHeight: sizing.value.p,
                margin: sizing.value.m,
            });

            setSportType('canoe-polo');
        });

        // Start the default sport
        setSportType('canoe-polo');
        if (options.autostart) {
            await tempGameStore.init();
            startGame();
        }
        updateDisplay();
    }

    // Watch for changes in timeLeft to update the display
    watch(timeLeft, () => {
        updateDisplay();
    });

    watch(sportType, () => {
        updateDisplay();
    });

    function updateDisplay() {
        if (!store.value || !matrix.value) {
            return;
        }

        store.value.fillScreen(null);

        const displayOptions = {
            display: store.value,
            matrix: matrix.value,
            gameStarted: gameStarted.value,
            formattedTimeLeft: formattedTimeLeft.value,
            formattedShotClock: formattedShotClock.value,
            scoreA: scoreA.value,
            scoreB: scoreB.value,
        };

        if (sportType.value == 'hockey') {
            useHockey(displayOptions);
        } else if (sportType.value == 'netball') {
            useNetball(displayOptions);
        } else if (sportType.value == 'canoe-polo') {
            useCanoePolo(displayOptions);
        }

        matrix.value?.render();
    }

    function startGame() {
        if (gameStarted.value) {
            if (paused.value) {
                // Resume the game
                if (pausedTimeLeft.value !== null) {
                    gameStartTime.value = currentTime.value - (totalGameTime.value * 1000 - pausedTimeLeft.value);
                } else {
                    gameStartTime.value = currentTime.value;
                }
                pausedTimeLeft.value = null;
                paused.value = false;
                tempGameStore.playGame();
            } else {
                // Pause the game
                pausedTimeLeft.value = timeLeft.value;
                gameStartTime.value = null; // Stop the timer
                paused.value = true;
                tempGameStore.pauseGame({
                    remaining_minutes: Math.floor((pausedTimeLeft.value / 1000) / 60),
                    remaining_seconds: Math.floor((pausedTimeLeft.value / 1000) % 60),
                });
            }
        } else {
            // Start the game for the first time
            gameStarted.value = true;
            gameStartTime.value = currentTime.value;
            pausedTimeLeft.value = null;
            paused.value = false;
            tempGameStore.startGame();
        }
    }


    function incrementScoreA() {
        if (tempGameStore.hasGame) {
            tempGameStore.incrementGame(0);
        }
        scoreA.value++;
        updateDisplay();
    }

    function incrementScoreB() {
        if (tempGameStore.hasGame) {
            tempGameStore.incrementGame(1);
        }
        scoreB.value++;
        updateDisplay();
    }

    function decrementScoreA() {
        if (scoreA.value <= 0) {
            return;
        }
        if (tempGameStore.hasGame) {
            tempGameStore.decrementGame(0);
        }
        scoreA.value--;
        updateDisplay();
    }

    function decrementScoreB() {
        if (scoreB.value <= 0) {
            return;
        }
        if (tempGameStore.hasGame) {
            tempGameStore.decrementGame(1);
        }
        scoreB.value--;
        updateDisplay();
    }

    function $reset() {
        gameStarted.value = false;
        paused.value = true;
        gameStartTime.value = null;
        pausedTimeLeft.value = null;
        scoreA.value = 0;
        scoreB.value = 0;
        updateDisplay();
    }

    return {
        createScoreboard,
        $reset,
        time: totalGameTime,
        formattedTimeLeft,
        formattedShotClock,
        incrementScoreA,
        incrementScoreB,
        decrementScoreA,
        decrementScoreB,
        scoreA,
        scoreB,
        handlePlayButtonPress: () => {
            startGame();
        },
        resetShotClock: () => {
            shotClockPassed.value = 0;
            updateDisplay();
        },
        isPaused: computed(() => paused.value),
        sportType,
        setSportType,
    };
});
